import {
  CreateAssignmentStep1,
  CreateAssignmentStep2,
  CreateAssignmentStep3,
  CreateAssignmentStep4,
  CreateAssignmentStep5,
} from '#components';
import type {Component} from 'vue';
import {useAppToast} from '~/stores/appToast';
import {AssignmentWayToPay} from '~/types';
import type {
  AssignmentCategory,
  AssignmentSubCategory,
  CalendarMultipleDateType,
  CalendarSingleDateType,
} from '~/types';

export const assignmentCreateComponents: Component[] = [
  CreateAssignmentStep1,
  CreateAssignmentStep2,
  CreateAssignmentStep3,
  CreateAssignmentStep4,
  CreateAssignmentStep5,
];

export const useCreateAssignmentStore = defineStore(
  'createAssignmentStore',
  () => {
    const {showApiErrorToast} = useAppToast();

    const assignmentStep = ref(0);
    const assignmentTitle = ref('');
    const assignmentDescription = ref('');
    const assignmentCategory = ref<AssignmentCategory>();
    const assignmentSubCategory = ref<AssignmentSubCategory>();
    const assignmentDesiredExecutionTime = ref<CalendarSingleDateType>();
    const assignmentDesiredExecutionTimeRange = ref<CalendarMultipleDateType>();
    const assignmentLocation = ref<google.maps.places.PlaceResult>();
    const assignmentPaymentType = ref(AssignmentWayToPay.Cash);
    const assignmentDesiredBudget = ref<number | null>(null);
    const assignmentMediaIds = ref<[string, File][]>([]);

    const isAssignmentLocationSkip = ref(false);
    const isAssignmentDesiredExecutionTimeSkip = ref(false);

    const assignmentDesiredExecutionShowTime = computed(() => {
      if (!Array.isArray(assignmentDesiredExecutionTimeRange.value))
        return false;

      const [, date2] = assignmentDesiredExecutionTimeRange.value;

      return !date2;
    });

    function createAssignment() {
      return useGetJobApi('/assignments/create', {
        method: 'POST',
        body: {
          categoryId: assignmentSubCategory.value?.id,
          title: assignmentTitle.value,
          description: assignmentDescription.value,
          desiredExecutionTime: isAssignmentDesiredExecutionTimeSkip.value
            ? undefined
            : assignmentDesiredExecutionShowTime.value
              ? {
                  dateTime: formatToTimeStringWithoutTimezone(
                    assignmentDesiredExecutionTime.value ||
                      (
                        assignmentDesiredExecutionTimeRange.value as Array<Date>
                      )[0],
                  ),
                }
              : {
                  dateRange: {
                    lowerBound: formatToTimeStringWithoutTime(
                      (
                        assignmentDesiredExecutionTimeRange.value as Array<Date>
                      )[0],
                    ),
                    uppedBound: formatToTimeStringWithoutTime(
                      (
                        assignmentDesiredExecutionTimeRange.value as Array<Date>
                      )[1],
                    ),
                  },
                },
          location: isAssignmentLocationSkip.value
            ? undefined
            : {
                coordinates: {
                  latitude: assignmentLocation.value?.geometry?.location?.lat(),
                  longitude:
                    assignmentLocation.value?.geometry?.location?.lng(),
                },
                formattedAddress: assignmentLocation.value?.formatted_address,
                googlePlaceId: assignmentLocation.value?.place_id,
              },
          desiredBudget: {
            amount: assignmentDesiredBudget.value!,
            currency: 'CZK',
          },
          paymentType: assignmentPaymentType.value,
          mediaFiles: assignmentMediaIds.value.map(([id]) => id),
        },
        onResponseError: showApiErrorToast,
      });
    }

    function hasAssignmentAnyValue(): boolean {
      if (assignmentStep.value > 0) return true;

      return (
        !!assignmentTitle.value ||
        !!assignmentCategory.value ||
        !!assignmentSubCategory.value
      );
    }

    function $reset() {
      assignmentStep.value = 0;
      assignmentTitle.value = '';
      assignmentCategory.value = undefined;
      assignmentSubCategory.value = undefined;
      assignmentDesiredExecutionTimeRange.value = undefined;
      assignmentDesiredExecutionTime.value = undefined;
      isAssignmentDesiredExecutionTimeSkip.value = false;
      isAssignmentLocationSkip.value = false;
      assignmentPaymentType.value = AssignmentWayToPay.Cash;
      assignmentDesiredBudget.value = null;
      assignmentLocation.value = undefined;
      assignmentDescription.value = '';
      assignmentMediaIds.value = [];
    }

    return {
      assignmentStep,
      assignmentTitle,
      assignmentCategory,
      assignmentSubCategory,
      assignmentDesiredExecutionTimeRange,
      assignmentDesiredExecutionTime,
      assignmentDesiredExecutionShowTime,
      isAssignmentDesiredExecutionTimeSkip,
      isAssignmentLocationSkip,
      assignmentPaymentType,
      assignmentDesiredBudget,
      assignmentLocation,
      assignmentDescription,
      assignmentMediaIds,
      hasAssignmentAnyValue,
      createAssignment,
      $reset,
    };
  },
);
